<template>
  <bottom-sheet-menu
    v-model="menu"
  >
    <div class="menu-header">
      <img src="/static/lk/logo.svg" class="logo"/>
      <v-spacer/>
      <div class="client-title">{{ client.title }}</div>
      <v-spacer/>
      <v-btn icon @click="hideMenu">
        <v-icon color="#4F3F3E">mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="manager-info">
      <div class="manager-info-title">Персональный менеджер</div>
      <div v-if="responsible.name" class="manager-info-text">{{ responsible.name }}</div>
      <div v-if="responsible.phone" class="manager-info-text">{{ responsible.phone }}</div>
      <div v-if="responsible.email" class="manager-info-text">{{ responsible.email }}</div>
    </div>
    <div class="menu-buttons">
      <mobile-btn
        href="/logout/" large depressed dark
      >Выйти
      </mobile-btn>
    </div>
  </bottom-sheet-menu>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import BottomSheetMenu from '@/components/BottomSheetMenu.vue'
import MobileBtn from '@/components/MobileBtn.vue'

export default {
  name: 'AppMenu',
  components: { MobileBtn, BottomSheetMenu },
  computed: {
    ...mapState(['showAppMenu', 'client', 'responsible']),
    menu: {
      get() {
        return this.showAppMenu
      },
      set(value) {
        this.setShowAppMenu({ value })
      }
    }
  },
  methods: {
    ...mapMutations(['setShowAppMenu']),
    hideMenu() {
      this.menu = false
    }
  }
}
</script>

<style scoped>
.menu-header {
  display: flex;
  align-items: center;
}

.client-title {
  font-size: 16px;
  font-weight: 500;
}

.logo {
  height: 40px;
  width: 40px;
}

.manager-info {
  background-color: rgba(237, 237, 237, 0.5);
  color: #4F3F3E;
  padding: 16px;
  margin-top: 24px;
  border-radius: 24px;
}

.manager-info-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

.manager-info-text {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}

.manager-info-text:last-child {
  margin-bottom: 0;
}

.menu-buttons {
  margin-top: 24px;
}


.exit-button {
  width: 100% !important;
  height: 48px !important;
  background-color: #443838 !important;
}

.exit-button-text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.002em;
  text-align: center;
  color: #fff;
}
</style>