<template>
  <v-navigation-drawer
    color="#564b4b"
    app dark elevation="0"
    :mini-variant.sync="rail"
    width="240"
    mini-variant-width="95"
    permanent
    style="overflow: visible"
  >
    <div>
      <a href="/" @click.stop style="margin: 40px 24px; display: block">
        <img
          src="/static/lk/logo-w.svg"
          height="48px"
        />
      </a>
    </div>
    <div
      class="rail-btn"
      :style="{right: rail ? '-22px' : '7px'}"
      @click.stop="rail =!rail"
    >
      <v-btn
        fab small dark
        :color="rail ? '#464140' : '#fff'"
        width="24px" height="24px"
        :text="!rail"
        @click.stop="rail = !rail"
      >
        <v-icon size="24">
          <template v-if="rail">mdi-chevron-double-right</template>
          <template v-else>mdi-chevron-double-left</template>
        </v-icon>
      </v-btn>
    </div>
    <v-list-item v-if="!rail" class="drawer-list-item py-10">
      <v-btn
        href="/lk/invoices/" class="br-16 px-12 exit-button" large
        depressed outlined dark style="background-color: #564b4b"
      ><span class="exit-button-text">Отчёты</span>
      </v-btn>
    </v-list-item>

    <template v-slot:append>
      <template v-if="!rail">
        <v-list class="py-10">
          <v-list-item class="drawer-list-item">
            <v-list-item-title class="drawer-subtitle">
              {{ client.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider class="divider"/>
        <v-list v-if="responsible" class="py-10">
          <v-list-item class="drawer-list-item">
            <v-list-item-title class="drawer-subtitle">
              Персональный менеджер
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="responsible.name" class="drawer-list-item">
            <v-list-item-title class="drawer-text">
              {{ responsible.name }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="responsible.phone" class="drawer-list-item">
            <v-list-item-title class="drawer-text">
              {{ responsible.phone }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="responsible.email" class="drawer-list-item">
            <v-list-item-title class="drawer-text">
              {{ responsible.email }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
      <v-list-item class="drawer-list-item last">
        <v-btn v-if="rail" fab dark text href="/logout/" @click.stop>
          <img
            src="/static/lk/logout.svg"
            height="56px"
          />
        </v-btn>
        <v-btn
          v-else
          href="/logout/" class="br-16 px-12 exit-button" large
          depressed outlined dark style="background-color: #564b4b"
        ><span class="exit-button-text">Выйти</span>
        </v-btn>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'AppDrawer',
  data() {
    return {
      rail: !!localStorage.getItem('appDrawerRail'),
    }
  },
  computed: {
    ...mapState(['client', 'responsible']),
  },
  watch: {
    rail: val => {
      if (val) {
        localStorage.setItem('appDrawerRail', '1')
      } else {
        localStorage.removeItem('appDrawerRail')
      }
    },
  },
}
</script>

<style scoped>
.client-title {
  font-size: 24px;
}

.exit-button {
  width: 100% !important;
  height: 48px !important;
}

.exit-button-text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.002em;
  text-align: center;
}

.rail-btn {
  position: absolute;
  top: 40px;
  z-index: 100;
  padding: 10px;
  cursor: pointer;
}

.rail-btn:hover .theme--dark.v-btn::before {
  opacity: 0.08;
}

.drawer-subtitle {
  font-size: 16px;
  font-weight: 500;
}

.drawer-text {
  font-size: 14px;
  font-weight: 400;
}

.drawer-list-item {
  padding: 0 24px;
  min-height: 30px;
}

.drawer-list-item.last {
  margin-bottom: 40px
}

.divider {
  margin: 0px 16px 0px 24px;
  color: rgba(255, 255, 255, 0.5);
  border-width: 1px;
}
</style>
<style>
.v-navigation-drawer__content {
  overflow: hidden;
}
</style>