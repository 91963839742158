import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import Touch from 'vuetify/lib/directives/touch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VBottomSheet,{staticClass:"modal",attrs:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('div',{directives:[{def: Touch,name:"touch",rawName:"v-touch",value:({
      start: _vm.onTouchDown,
      end: _vm.onTouchUp,
      move: _vm.onTouchMove,
    }),expression:"{\n      start: onTouchDown,\n      end: onTouchUp,\n      move: onTouchMove,\n    }"}],staticClass:"menu",style:({'margin-bottom': (_vm.menuOffset + "px")})},[_c('div',{staticClass:"menu-bar"},[_c('img',{staticClass:"bar",attrs:{"src":"/static/lk/img/bar.svg"}})]),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }