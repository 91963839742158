<template>
  <v-bottom-sheet
    :value="value"
    class="modal"
    @input="$emit('input', $event)"
  >
    <div
      class="menu"
      :style="{'margin-bottom': `${menuOffset}px`}"
      v-touch="{
        start: onTouchDown,
        end: onTouchUp,
        move: onTouchMove,
      }"
    >
      <div
        class="menu-bar"
      >
        <img src="/static/lk/img/bar.svg" class="bar"/>
      </div>
      <slot/>
    </div>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'BottomSheetMenu',
  props: ['value'],
  data() {
    return {
      touchInProgress: false,
      touchStartY: null,
      touchCurrentY: null,
    }
  },
  computed: {
    menuOffset() {
      const { touchInProgress, touchStartY, touchCurrentY } = this
      if (touchInProgress && touchCurrentY > touchStartY) {
        return touchStartY - touchCurrentY
      }
      return 0
    }
  },
  watch: {
    value(value) {
      if (value) {
        this.touchInProgress = false
      }
    },
  },
  methods: {
    onTouchDown(e) {
      this.touchInProgress = true
      this.touchStartY = e.touchstartY
      this.touchCurrentY = e.touchstartY
    },
    onTouchUp(e) {
      if (this.menuOffset < -100) {
        this.$emit('input', false)
      } else {
        this.touchInProgress = false
      }
    },
    onTouchMove(e) {
      this.touchCurrentY = e.touchmoveY
    },
  },
}
</script>

<style scoped>
.modal {
}

.menu {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background-color: #fff;
  color: #4F3F3E;
  padding: 10px;
}

.menu-bar {
  padding: 2px 0 12px 0;
  display: flex;
  align-items: center;
}

.bar {
  margin: auto;
}
</style>