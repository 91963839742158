<template>
  <v-btn
    class="mobile-btn" :class="{plain}"
    :disabled="disabled" :href="href" width="100%" depressed
    @click="$emit('click', $event)"
  >
    <span class="mobile-btn-text">
      <slot/>
    </span>
  </v-btn>
</template>

<script>
export default {
  name: 'mobile-btn',
  props: ['disabled', 'href', 'plain'],
}
</script>

<style scoped>

.mobile-btn {
  width: 100% !important;
  height: 48px !important;
  background-color: #443838 !important;
  border-radius: 32px;
}

.mobile-btn-text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.002em;
  text-align: center;
  color: #fff;
}

.mobile-btn.plain {
  background-color: #44383833 !important;
}

.mobile-btn.plain .mobile-btn-text {
  color: #44383899 !important;
}
</style>