<template>
  <v-app-bar
    class="app-bar"
    app elevation="0"
    height="48px"
  >
    <template v-if="pageReturnLink">
      <v-btn icon :href="pageReturnLink">
        <v-icon color="#564B4B">mdi-chevron-left</v-icon>
      </v-btn>
    </template>
    <template v-else>
      <a href="/lk/" class="d-flex align-center">
        <img src="/static/lk/logo.svg" height="32px"/>
      </a>
    </template>
    <v-spacer/>
    <template v-if="pageTitleLoading">
      <div style="width: 200px">
        <v-skeleton-loader type="heading" style="width: 100%"/>
      </div>
    </template>
    <template v-else>
      <div class="page-title">{{ title }}</div>
    </template>
    <v-spacer/>
    <v-btn icon @click="setShowAppMenu({value: true})">
      <v-icon color="#4F3F3E">mdi-menu</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import {mapMutations, mapState} from 'vuex'

export default {
  name: 'AppBar',
  computed: {
    ...mapState(['client', 'pageTitle', 'pageTitleLoading', 'pageReturnLink']),
    title() {
      return this.pageTitle || this.client.title
    }
  },
  methods: {
    ...mapMutations(['setShowAppMenu']),
  }
}
</script>

<style scoped>
.app-bar {
  background-color: #F2F2F2;
}

.page-title {
  font-size: 16px;
  font-weight: 500;
  color: #4F3F3E;
}

.exit-button {
  width: 160px !important;
  height: 48px !important;
}

.exit-button-text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.002em;
}
</style>
<style>
.app-bar .v-skeleton-loader__heading {
  width: 100%;
  height: 24px;
  border-radius: 2px
}
</style>